<template>
  <div>
    <HeaderMultiPageRoute :language.sync="language">
      <div slot="logo" class="d-flex logos">
        <img class="logo-img" src="../assets/img/logo/logo.svg" alt="ロゴ画像"/>
        <img class="logo-sign-img" src="../assets/img/logo/logo-sign.svg" alt="ロゴ文字画像"/>
      </div>
    </HeaderMultiPageRoute>

    <hooper ref="carousel" :settings="settings" @slide="onSlideChange">
      <slide v-for="(slider, i) in sliderContent" :key="i">
        <div
          class="swiper-slide section slider-area fullscreen-slider fullscreen d-flex align-items-center bg_image award-content"
          :style="{ backgroundImage: 'url(' + slider.src + ')' }"
        >
          <v-container>
            <v-row class="d-flex fullscreen" align="center">
              <v-col cols="12">
                <div class="content text-center wrap-text" style="margin-top: 30px;">
                  <h2>{{ slider.title }}</h2>
                  <p class="subtitle wrap-text text-shadow" style="max-width: 960px; display: inline-block;">
                    {{ slider.desc }}
                  </p>
                  <div style="font-size: 1rem;" v-if="slider.position==='our-story'">
                    <p class="subtitle wrap-text text-shadow">{{ slider.memo }}</p>
                  </div>
                  <ul class="rn-award-list">
                    <div class="row justify-center" v-if="slider.position==='isms'" style="margin-top: 10px;">
                      <div class="column" style="padding: 8px 10px; font-size: 0.7rem;">
                        <div> <a href="https://www.icms.co.jp/registration/2024/10/20241004-1183.html" target="_blank">{{language == 'ja' ? '認証機関' : 'Certification Body'}}</a></div> 
                        <a href="https://isms.jp/lst/ind/CR_ICMS-SR0691.html" target="_blank">
                          <img class="logo-sign-img" src="../assets/img/logo/isms-logo.png" style="max-width: 150px;" alt="ICMS-SR0691"/>
                        </a>
                      </div>  
                      <div class="column" style="padding: 10px 10px; font-size: 0.5rem;">
                        <a href="https://drive.google.com/file/d/13hZF3DaSoBPvuxOB5csor-pGt9nAti-c/view?usp=sharing" target="_blank">
                          <img class="logo-sign-img" src="../assets/img/pdf-icon.png" style="max-width: 70px; margin-top: 20px;"/>
                        </a>  
                        <div style="max-width: 120px;">{{language == 'ja' ? '情報セキュリティ基本方針書' : 'Information Security Basic Policy Document'}}</div> 
                      </div>  
                    </div>  
                    <div class="row justify-center" v-if="slider.position==='carrier'">
                      <div class="row justify-center" style="padding: 20px; font-size: 0.8rem;">
                        <div style="padding: 3px 8px;">{{ slider.label }}: A-22-11097</div> 
                        <a href="https://www.soumu.go.jp/johotsusintokei/field/tsuushin04_01.html" target="_blank">
                          <img class="logo-sign-img" src="../assets/img/external-icon.png" style="max-width: 20px;"/>
                        </a>  
                      </div>  
                    </div>  
                    <li v-for="(item, i) in slider.awardList" :key="i" style="background-color: #ffffff77; padding: 15px; border-radius: 1rem">
                      <p class="wrap-text text-shadow"
                        >{{ item.name }}</p
                      >
                      <p class="wrap-text text-shadow" style="font-size: 0.80rem; line-height: 24px;">
                        {{ item.description }}
                      </p>
                    </li>
                  </ul>

                  <ul class="rn-award-list">
                    <li v-for="(item, i) in slider.contactInfo" :key="i">
                      <div v-if="item.name === 'Address'" class="text-shadow" style="margin-top: 60px;">
                        <span style="font-size: 1.2rem">{{language == 'ja' ? 'BSM株式会社' : 'BSM Inc.'}}</span><br>
                        <span style="margin-top: 10px; font-size: 0.8rem; display: block; line-height: 1.5rem;">{{ item.desc }}</span>
                        <a href="https://maps.app.goo.gl/K2A2dv3UMrzFxGG16" target="_blank" style="margin-top: 15px;">
                          <i v-html="iconSvg('map')" style="margin-right: 8px;"/> 
                          <span style="position:relative; top: -6px; font-size: 0.8rem;">{{language == 'ja' ? 'マップを開く' : 'Open Map'}}</span>
                        </a>
                        <br>
                        <div style="margin-top: 20px; font-size: 0.8rem;">{{language == 'ja' ? 'その他事業所' : 'Other Business Locations'}}</div> 
                        <span style="margin-top: 10px; font-size: 0.8rem; display: block;">{{ item.desc2 }}</span>
                      </div>
                      <a :href="item.to" target="_blank">
                        <p v-if="item.name === 'Mail'" style="font-size: 0.9rem" class="text-shadow">
                          <i v-html="iconSvg('mail')" style="margin-right: 5px;"/> 
                          <span style="position:relative; top: -6px; font-size: 0.8rem;">{{language == 'ja' ? 'フォームを開く' : 'Open Form'}}</span>
                        </p>
                      </a>
                    </li>
                  </ul>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </div>
        <!-- End Hero Area -->
      </slide>
      <hooper-pagination slot="hooper-addons"></hooper-pagination>
    </hooper>
  </div>
</template>

<script>
  import HeaderMultiPageRoute from "../components/header/HeaderMultiPageRoute";
  import { Hooper, Slide, Pagination as HooperPagination } from "hooper";
  import feather from "feather-icons";

  export default {
    components: {
      HeaderMultiPageRoute,
      Hooper,
      Slide,
      HooperPagination,
    },
    data() {
      return {
        sliderContentBoth: {
          ja: [
            {
              src: require("../assets/img/slider-6/slider-1.jpg"),
              position: "home",
              title: "Be Simple",
              desc: ` 実直で俊敏に、目に見える成果を`,
            },
            {
              src: require("../assets/img/slider-6/slider-2.jpg"),
              position: "our-story",
              title: "Our Story",
              desc: ` BSMは2007年の事業開始以来、専門家がパートナー企業と連携し、最新のソリューションをローコストで提供しています。`,
              memo: '2024年1月に株式会社への組織変更を行いました。'
            },
            {
              src: require("../assets/img/slider-6/slider-3.jpg"),
              position: "services",
              title: "Services",
              desc: ``,
              awardList: [
                {
                  name: "ハイブリッドアプリケーション開発",
                  description: ` 高品質・高効率な開発でビジネスをサポートいたします。海外の専門性の高い開発者と連携し、最善のアプローチで製品を開発します。`,
                },
                {
                  name: "クラウド, IoT, AI技術の活用",
                  description: ` GCP, AWSなどのクラウドサービス,IoT,AIの最新技術を低コストで導入、運用します。`,
                }
              ],
            },
            {
              src: require("../assets/img/slider-6/slider-4.jpg"),
              position: "isms",
              title: "ISMS",
              desc: `情報セキュリティ管理を通じて、安心・安全な社会の実現に貢献します`,
              awardList: [
                {
                  name: "ISMS（情報セキュリティマネジメントシステム）",
                  description: ` 私たちは、JIS Q 27001に基づく厳格なセキュリティ管理体制と継続的な改善を通じて、情報漏洩やリスクを最小限に抑え、セキュリティ要求の厳しいプロダクト開発においても、安心してお任せいただけるサービスを提供します。`,
                },
              ],
            },
            {
              src: require("../assets/img/slider-6/slider-5.jpg"),
              position: "carrier",
              title: "Carrier",
              desc: `第二種電気通信事業を通じて社会貢献を行います`,
              label: '届出番号',
              awardList: [
                {
                  name: "第二種電気通信事業",
                  description: ` 私たちは多様なニーズに対応した技術革新とサービス向上を追求し、第二種電気通信事業として情報社会のインフラとしての役割を担い、地域社会とともに成長することを目指しています。`,
                }
              ],
            },
            {
              src: require("../assets/img/slider-6/slider-6.jpg"),
              position: "contact",
              title: "Contact US",
              desc: ` お気軽にお問い合わせください`,
              contactInfo: [
                {
                  name: "Mail",
                  to: "https://forms.gle/zZTpAv9tnHmwHscP9",
                },
                {
                  name: "Address",
                  desc: "〒105-5117\n東京都港区浜松町2-4-1\n世界貿易センタービルディング南館17階",
                  desc2: "〒108-0075 東京都港区港南4丁目1番6号",
                  to: "",
                },
              ],
            },
          ],
          us: [
            {
              src: require("../assets/img/slider-6/slider-1.jpg"),
              position: "home",
              title: "Be Simple",
              desc: `Delivering tangible results with integrity and agility.`,
            },
            {
              src: require("../assets/img/slider-6/slider-2.jpg"),
              position: "our-story",
              title: "Our Story",
              desc: ` Since starting in 2007, BSM has partnered with companies to provide cutting-edge solutions at low cost, backed by expert support.`,
              memo: 'In January 2024, we transitioned from LLC to a corporation.'
            },
            {
              src: require("../assets/img/slider-6/slider-3.jpg"),
              position: "services",
              title: "Services",
              desc: ``,
              awardList: [
                {
                  name: "Hybrid Application Development",
                  description: ` Supporting your business with high-quality, efficient development. We collaborate with highly skilled international developers to create products using the best possible approach.`,
                },
                {
                  name: "Leveraging Cloud, IoT, and AI Technologies",
                  description: ` We implement and manage the latest in GCP, AWS, IoT, and AI technologies at low cost.`,
                }
              ],
            },
            {
              src: require("../assets/img/slider-6/slider-4.jpg"),
              position: "isms",
              title: "ISMS",
              desc: `Contributing to a secure and safe society through robust information security management.`,
              awardList: [
                {
                  name: "ISMS",
                  description: ` information security management system - We provide reliable services you can trust, even in high-security product development, by implementing a strict security management system based on JIS Q 27001 and continuously improving to minimize information leakage and risks.`,
                },
              ],
            },
            {
              src: require("../assets/img/slider-6/slider-5.jpg"),
              position: "carrier",
              title: "Carrier",
              desc: `We contribute to society through our Type II telecommunications services.`,
              label: 'Registration Number',
              awardList: [
                {
                  name: "Type II Telecommunications Business",
                  description: ` We strive for technological innovation and service enhancement to meet diverse needs, fulfilling our role as infrastructure in the information society as a Type II Telecommunications Business, and aim to grow alongside the local community.`,
                }
              ],
            },
            {
              src: require("../assets/img/slider-6/slider-6.jpg"),
              position: "contact",
              title: "Contact US",
              desc: ` Please feel free to contact us.`,
              contactInfo: [
                {
                  name: "Mail",
                  to: "https://forms.gle/zZTpAv9tnHmwHscP9",
                },
                {
                  name: "Address",
                  desc: "〒105-5117\nWorld Trade Center Building South Tower, 17th Floor, 2-4-1 Hamamatsucho, Minato-ku, Tokyo",
                  desc2: "〒108-0075 4-1-6 Konan, Minato-ku, Tokyo",
                  to: "",
                },
              ],
            },
          ]
        },
        settings: {
          vertical: true,
          mouseDrag: false,
          infiniteScroll: true,
          transition: 1200,
        },
        language: 'ja'
      };
    },
    computed: {
      sliderContent: {
        get() {
          return this.sliderContentBoth[this.language];
        }
      }
    },
    methods: {
      iconSvg(icon) {
        return feather.icons[icon].toSvg();
      },
      onSlideChange({currentSlide}) {
        let index = 0
        if (currentSlide == -1) index = this.sliderContent.length - 1
        else if (currentSlide >= this.sliderContent.length - 1) index = this.sliderContent.length - 1
        else index = currentSlide
        const { position } = this.sliderContent[index]
        history.replaceState(null, '', `/slide/${position}`)
      }
    },
    mounted () {
      const path = new URL(document.baseURI).pathname
      if (path !== '/') {
        const index = this.sliderContent.map(i => i.position).indexOf(path.replace('/slide/', ''))
        if (index > -1) {
          this.$refs.carousel.slideTo(index)
        }
      }
      const browserLang = (navigator.language || navigator.userLanguage).startsWith('ja') ? 'ja' : 'us'
      const lng = localStorage.getItem('language') || browserLang
      if (lng) this.language = lng
    },
    watch: {
      language(lng) {
        localStorage.setItem('language', lng)
      },
      $route(to) {
        const param = to.params
        const index = this.sliderContent.map(i => i.position).indexOf(param.page)
        if (to.path === '/') { 
          this.$refs.carousel.slideTo(0);
        } else if (index === -1) {
          this.$router.push('/')
        } else {
          this.$refs.carousel.slideTo(index);
        }
      }
    }
  };
</script>

<style lang="scss" scoped>
  .subtitle {
    padding: 0 10px;
    @media only screen and (max-width: 767px) {
      padding: 0;
    }
  }
  .logos {
    align-items: center;
    @media only screen and (max-width: 767px) {
      margin-top: 10px;
    }
    .logo-img {
      height: auto;
      width: 50px;
      @media only screen and (max-width: 767px) {
        width: 40px;
      }
    }
    .logo-sign-img {
      height: auto;
      width: 100px;
      margin-left: 10px;
      @media only screen and (max-width: 767px) {
        margin-left: 5px;
        width: 70px;
      }
    }
  }
  .wrap-text {
    word-wrap : break-word;
    overflow-wrap : break-word;
    white-space: pre-line;
    line-break: auto;
    word-break: break-word;
  }
  .text-shadow {
    text-shadow:
      2px 2px 2px rgb(251, 251, 251), -2px -2px 2px rgb(251, 251, 251),
      -2px 2px 2px rgb(251, 251, 251),  2px -2px 2px rgb(251, 251, 251);
  }
  .google-map {
    position: fixed;
    left: 0;
    width: 100%;
  }
  .gmap_canvas {
    overflow: hidden;
    background: none !important;
    height: 800px;
    width: 100%;

    iframe {
      width: 100%;
      height: 100%;
      max-height: 200px;
      margin-top: 20px;
    }
  }
  .mapouter {
    position: relative;
    width: 100%;
    height: 100%;
  }
</style>
